.container {
  background-color: rgba(0, 0, 0, 0.5);
}

.backgroundImage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
      to bottom right,
      rgba(0, 0, 0, 1),
      rgba(255, 255, 255, 0.3)
    ),
    url("/src/assets/felipe-portella-h6gTtqBZUxc-unsplash.jpg");
  background-position-y: 30%;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.wipContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
}

.socialLogo {
  cursor: pointer;
  width: 3rem;
  margin: 0.5rem;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  padding-top: 3.5rem;
}

.title {
  padding-top: 3.5rem;
  font-weight: 900;
  color: white;
  font-size: 5.5rem;
  text-align: center;
  line-height: 90%;
}

@media only screen and (max-width: 600px) {
  .backgroundImage {
    background-position-x: 70%;
  }

  .title {
    font-size: 2rem;
  }
}
