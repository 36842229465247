@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-BlackItalic.ttf");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-ExtraBoldItalic.ttf");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-ExtraLight.ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-ExtraLightItalic.ttf");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Italic.ttf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-LightItalic.ttf");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-SemiBoldItalic.ttf");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Popppins";
  src: url("./fonts/Poppins-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-ThinItalic.ttf");
  font-weight: 100;
  font-style: italic;
}
